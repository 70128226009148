import React, { useState } from "react"

import { graphql } from "gatsby"
import ElevatorContent from "../components/ElevatorContent"
import ElevatorHero from "../components/ElevatorHero"
import Layout from "../components/layout"
import { MultiPageFormProvider } from "../components/ContextProviders/MultiPageFormProvider"
import ElevatorForm from "../components/elevatorInquiry/ElevatorForm"
import PageEndBanner from "../components/PageEndBanner"
import Seo from "../components/seo"

const Escalator = ({ pageContext, data: { escalator, miscString } }) => {
  const [open, setOpen] = useState(false)
  return (
    <Layout pageData={pageContext}>
      <Seo
        title={escalator.title}
        seoTitle={escalator.seo?.seoTitle}
        seoDescription={escalator.seo?.seoDescription}
        seoImage={escalator.seo?.image?.seoImageUrl}
      />
      <ElevatorHero
        isHero
        gradient={true}
        description={escalator.description}
        tag={escalator.tag}
        title={escalator.title}
        cta={miscString.nodes[0]?.getInquiry}
        open={open}
        setOpen={() => setOpen(!open)}
        heroimage={escalator.hero.gatsbyImageData}
        bottomImage
        isDark
      />
      <MultiPageFormProvider>
        <ElevatorForm open={open} setOpen={() => setOpen(false)} />
      </MultiPageFormProvider>
      {escalator.content?.value && (
        <ElevatorContent structuredData={escalator.content} />
      )}

      <PageEndBanner onClick={() => setOpen(!open)} />
    </Layout>
  )
}

export default Escalator

export const query = graphql`
  query escalatorQuery($id: String, $locale: String) {
    escalator: datoCmsEscalator(
      originalId: { eq: $id }
      locale: { eq: $locale }
    ) {
      id
      title
      locale
      slug
      description
      tag
      hero {
        gatsbyImageData(height: 400)
      }
      content {
        value

        blocks {
          ... on DatoCmsFinishChoice {
            id: originalId
            __typename
            finish {
              gatsbyImageData
              title
            }
            title
          }
          ... on DatoCmsConfrontationBlock {
            id: originalId
            __typename
            title
            data
            icon {
              gatsbyImageData(height: 35, width: 35)
            }
          }
          ... on DatoCmsTwoColumnContentImage {
            id: originalId
            __typename
            title
            specs {
              specs
            }
            image {
              url
              gatsbyImageData(height: 500)
            }
            content {
              value
            }
            cta
            link {
              ... on DatoCmsCabinPage {
                id: originalId
              }
              ... on DatoCmsElectronicPage {
                id: originalId
              }
              ... on DatoCmsElectronic {
                id: originalId
              }
            }
          }
        }
      }

      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
    cabinUrl: datoCmsCabinPage(locale: { eq: $locale }) {
      id: originalId
    }
    displayUrl: datoCmsMenuCategory(
      locale: { eq: $locale }
      value: { eq: "display" }
    ) {
      id: originalId
      url
      value
    }
    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        getInquiry
      }
    }
  }
`
